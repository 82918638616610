// src/components/Travel/index.js
import React, { Component } from 'react';
import { Container, DropdownButton, Dropdown } from 'react-bootstrap'

import GoogleMapsEmbed from './GoogleMapsEmbed';

const CITIES = {
    "kauai": { title: "Kauai (Hawaii), USA", embedID: "1TfnVQqej6fmtRVj9NiVAwZUQSH9Wqd0" },
    "oahu": { title: "O'ahu (Hawaii), USA", embedID: "1vwc3506KGZdnxLln6o8NZaE23h97gfQ" },
    "mexico-city": { title: "Mexico City, MX", embedID: "1CbZt6cu7YYOY3K_Yqqr_CaPhVrZ6zWcI" },
};

function buildDropdownItems(cities) {
    const dropdownItems = []
    for (const city in cities) {
        dropdownItems.push((<Dropdown.Item eventKey={city}>{CITIES[city].title}</Dropdown.Item>))
    }
    return dropdownItems
}

class Travel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "kauai",
            activeTitle: CITIES["kauai"].title
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedKey) {
        this.setState({
            activeKey: selectedKey,
            activeTitle: CITIES[selectedKey].title
        });
    }

    render() {
        let embedID = CITIES[this.state.activeKey].embedID;
        let body = <GoogleMapsEmbed embedID={embedID}/>;

        return (
            <Container>
                <h3>Travel favorites</h3>
                <DropdownButton id="dropdown-basic-button" title={this.state.activeTitle} onSelect={this.handleSelect}> 
                    {
                        buildDropdownItems(CITIES)
                    }
                </DropdownButton>
                {body}
            </Container>
        );
    }
}

export default Travel;
