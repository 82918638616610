// src/components/Projects/Kaboom/index.js
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

class Kaboom extends Component {
    render() {
        return (
            <Container>
                <h3>Kaboom!</h3>
                <p>Experimenting with <a href="https://replit.com/">Replit</a> IDE + hosting and the <a href="https://kaboomjs.com/">Kaboom</a> JS library. See hosted site at <a href="https://kaboom.evanwchen.repl.co/">https://kaboom.evanwchen.repl.co/</a>.</p>
                <div className="container-iframe">
                    <iframe 
                        src="https://kaboom.evanwchen.repl.co/"
                        className="responsive-iframe"
                    />
                </div>
            </Container>
        );
    }
}

export default Kaboom;
