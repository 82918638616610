// src/components/Projects/index.js
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { Link } from "react-router-dom";

class Projects extends Component {
    render() {
        return (
            <Container>
                <h3>Random projects</h3>
                <ul>
                    <li>
                        <Link to="/projects/youtube">YouTube</Link>: Experimenting with YouTube Embedded Players
                    </li>
                    <li>
                        <Link to="/projects/kaboom">Kaboom game</Link>: Experimenting with Replit
                    </li>
                    <li>
                        <Link to="/projects/calculator">Calculator</Link>: System design calculator
                    </li>
                </ul>
            </Container>
        );
    }
}

export default Projects;
