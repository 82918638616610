import React from "react";
import PropTypes from "prop-types";

const GoogleMapsEmbed = ({ embedID }) => (
  <div className="container-iframe">
    <iframe 
        src={`https://www.google.com/maps/d/embed?mid=${embedID}&ehbc=2E312F"`}
        className="responsive-iframe"
    />
  </div>
);

GoogleMapsEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default GoogleMapsEmbed;
