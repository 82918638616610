// src/components/Projects/Calculator/index.js
import React, { Component } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'

class Calculator extends Component {
    constructor() {
        super();
        this.state = {
            data: {
                byte: 0,
                kb: 0,
                mb: 0,
                gb: 0,
                tb: 0
            }
        };
        this.convertByte = this.convertByte.bind(this)
        this.convertKB = this.convertKB.bind(this)
        this.convertMB = this.convertMB.bind(this)
        this.convertGB = this.convertGB.bind(this)
        this.convertTB = this.convertTB.bind(this)
      }

    convertByte(e) {
        let newData = {}
        newData.byte = e.target.value
        newData.kb = Math.round(e.target.value/1024*100000)/100000
        newData.mb = Math.round(e.target.value/1048576*100000)/100000
        newData.gb = Math.round(e.target.value/1073741824*100000)/100000
        newData.tb = Math.round(e.target.value/1099511627776*100000)/100000  
        this.setState({ data: newData });   
    }

    convertKB(e) {
        let newData = {}
        newData.kb = e.target.value
        newData.byte = Math.round(e.target.value*1024*100000)/100000
        newData.mb = Math.round(e.target.value/1024*100000)/100000
        newData.gb = Math.round(e.target.value/1048576*100000)/100000
        newData.tb = Math.round(e.target.value/1073741824*100000)/100000
        this.setState({ data: newData }); 
    }

    convertMB(e) {
        let newData = {}
        newData.mb = e.target.value
        newData.byte = Math.round(e.target.value*1048576*100000)/100000
        newData.kb = Math.round(e.target.value*1024*100000)/100000
        newData.gb = Math.round(e.target.value/1024*100000)/100000
        newData.tb = Math.round(e.target.value/1073741824*100000)/100000
        this.setState({ data: newData }); 
    }

    convertGB(e) {
        let newData = {}
        newData.gb = e.target.value
        newData.byte = Math.round(e.target.value*1073741824*100000)/100000
        newData.kb = Math.round(e.target.value*1048576*100000)/100000
        newData.mb = Math.round(e.target.value*1024*100000)/100000
        newData.tb = Math.round(e.target.value/1024*100000)/100000
        this.setState({ data: newData });   
    }
    
    convertTB(e) {
        let newData = {}
        newData.tb = e.target.value
        newData.byte = Math.round(e.target.value*1099511627776*100000)/100000
        newData.kb = Math.round(e.target.value*1073741824*100000)/100000
        newData.mb = Math.round(e.target.value*1048576*100000)/100000
        newData.gb = Math.round(e.target.value*1024*100000)/100000
        this.setState({ data: newData }); 
    }

    shortenNumber(number) {
        return Intl.NumberFormat('en-US', {
            notation: "compact",
            maximumFractionDigits: 2
        }).format(number)
    } 

    render() {
        let byte = this.state.data.byte.toLocaleString("en-US");
        let kb = this.state.data.kb.toLocaleString("en-US");
        let mb = this.state.data.mb.toLocaleString("en-US");
        let gb = this.state.data.gb.toLocaleString("en-US");
        let tb = this.state.data.tb.toLocaleString("en-US");
        let shortenedByte = this.shortenNumber(this.state.data.byte)
        let shortenedKB = this.shortenNumber(this.state.data.kb)
        let shortenedMB = this.shortenNumber(this.state.data.mb)
        let shortenedGB = this.shortenNumber(this.state.data.gb)
        let shortenedTB = this.shortenNumber(this.state.data.tb)

        return (
            <Container>
                <h3>Calculator</h3>
                <Form className="mb-2">
                    <Row>
                        <Form.Group as={Col} controlId="formGridByte" onChange={this.convertByte}>
                            <Form.Label>Byte</Form.Label>
                            <Form.Control type="text" value={byte} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridKB" onChange={this.convertKB}>
                            <Form.Label>KB</Form.Label>
                            <Form.Control type="text" value={kb} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridMB" onChange={this.convertMB}>
                            <Form.Label>MB</Form.Label>
                            <Form.Control type="text" value={mb} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridGB" onChange={this.convertGB}>
                            <Form.Label>GB</Form.Label>
                            <Form.Control type="text" value={gb} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTB" onChange={this.convertTB}>
                            <Form.Label>TB</Form.Label>
                            <Form.Control type="text" value={tb} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formGridByte">
                            <Form.Control type="text" placeholder={shortenedByte} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridKB">
                            <Form.Control type="text" placeholder={shortenedKB} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridMB">
                            <Form.Control type="text" placeholder={shortenedMB} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridGB">
                            <Form.Control type="text" placeholder={shortenedGB} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTB">
                            <Form.Control type="text" placeholder={shortenedTB} readOnly />
                        </Form.Group>
                    </Row>
                </Form>
            </Container>
        );
    }
}

export default Calculator;
