// src/components/Header/index.js
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import './style.css';

class Header extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg">
                <Container className='header'>
                    <Navbar.Brand>Evan Chen ✌️</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav variant="pills" defaultActiveKey="/" as="ul">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="1" as={NavLink} to="/">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="2" as={NavLink} to="/about">About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="3" as={NavLink} to="/projects">Projects</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="4" as={NavLink} to="/travel">Travel</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="5" as={NavLink} to="/contact">Contact</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>  
        );
    }
}

export default Header;
