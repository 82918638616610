// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

import App from './components/App';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import YouTube from './components/Projects/YouTube';
import Kaboom from './components/Projects/Kaboom';
import Calculator from './components/Projects/Calculator';
import Travel from './components/Travel';
import Contact from './components/Contact';
import './index.css';

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <BrowserRouter>
        <Header/>
        <Routes>
            <Route exact path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route exact path="/projects" element={<Projects />} />
            <Route path="/projects/youtube" element={<YouTube />} />
            <Route path="/projects/kaboom" element={<Kaboom />} />
            <Route path="/projects/calculator" element={<Calculator />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/contact" element={<Contact />} />
        </Routes>
    </BrowserRouter>
);