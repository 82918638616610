// src/components/Contact/index.js
import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import github from '../../assets/github.png';
import instagram from '../../assets/instagram.png';
import twitter from '../../assets/twitter.png';
import medium from '../../assets/medium.png';
import linkedin from '../../assets/linkedin.png';
import './style.css';

class Contact extends Component {
    render() {
        return (
            <Container>
                <h3>Say hello!</h3>
                <a href="https://www.github.com/evanwchen" className="social_icon">
                    <Image src={github} />
                </a>
                <a href="https://www.instagram.com/evanwchen" className="social_icon">
                    <Image src={instagram} />
                </a>
                <a href="https://www.twitter.com/evanchen" className="social_icon">
                    <Image src={twitter} />
                </a>
                <a href="https://medium.com/@evanchen" className="social_icon">
                    <Image src={medium} />
                </a>
                <a href="https://www.linkedin.com/in/evanwchen" className="social_icon">
                    <Image src={linkedin} />
                </a>
            </Container>
        );
    }
}

export default Contact;
