import React from "react";
import PropTypes from "prop-types";

const YouTubeEmbed = ({ embedID }) => (
  <div className="container-iframe">
    <iframe
      src={`https://www.youtube.com/embed/${embedID}?autoplay=1&loop=1&playlist=${embedID}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className="responsive-iframe"
    />
  </div>
);

YouTubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YouTubeEmbed;
