// src/components/Projects/YouTube/index.js
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import YouTubeEmbed from './YouTubeEmbed';

class YouTube extends Component {
    render() {
        return (
            <Container>
                <h3>BTS 💜</h3>
                <p>#ARMY supports BTS by streaming their new songs across all platforms on all their devices.</p>
                <p>This was a quick project to support the release of "Yet To Come".</p>
                <p>I set up a <a href="https://developers.google.com/youtube/player_parameters">YouTube embedded player</a> that would play a video on repeat.</p>
                <YouTubeEmbed embedID="kXpOEzNZ8hQ" />
            </Container>
        );
    }
}

export default YouTube;
